import './TrustedBy.scss';

import { Icon } from '@travelwin/core';
import { t } from 'i18next';

export const TRIPADVISOR_RATING_URL =
  'https://www.tripadvisor.co.uk/Attraction_Review-g186338-d24151194-Reviews-Sim_Local-London_England.html';

export const TRIPADVISOR_ICON = 'tripadvisorRatingIcon';
export const GOOGLE_ICON = 'googleRatingIcon';

const TrustedBy = () => {
  return (
    <div className="c-TrustedBy">
      <h2 className="c-TrustedBy__title">
        {t('trusted_by.title', 'Trusted by travellers')}
      </h2>
      <div className="c-TrustedBy__icons">
        <div className="c-TrustedBy__icon">
          <a
            href={TRIPADVISOR_RATING_URL}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon name={TRIPADVISOR_ICON} />
          </a>
        </div>
        <div className="c-TrustedBy__icon">
          <Icon name={GOOGLE_ICON} />
        </div>
      </div>
    </div>
  );
};

export default TrustedBy;
